import { PlusIcon } from '@heroicons/react/24/outline/index';
import { Key } from '@phosphor-icons/react';
import { createLazyRoute } from '@tanstack/react-router';
import { useState } from 'react';

import { QueriedList } from '@/components/list/QueriedList.tsx';
import { Button } from '@/components/ui/Button.tsx';
import DashboardHeader from '@/components/ui/layout/dashboard-layout/DashboardHeader.tsx';
import { listApiKeysInfiniteQuery } from '@/features/administrator-panel/api-keys/api/apiKeysApi.ts';
import { APIKeyListItem } from '@/features/administrator-panel/api-keys/APIKeyListItem.tsx';
import { CreateAPIKeyDialog } from '@/features/administrator-panel/api-keys/CreateAPIKeyDialog.tsx';

export const API_KEYS_PER_PAGE = 10;

export const APIKeys = () => {
  const [createKeyDialogOpen, setCreateKeyDialogOpen] = useState(false);

  return (
    <>
      <DashboardHeader className={'flex justify-center items-center'}>
        <Key className={'w-8 h-8 text-gray-800 mr-2 inline '} />
        API Keys
      </DashboardHeader>

      <QueriedList
        query={listApiKeysInfiniteQuery(API_KEYS_PER_PAGE)}
        className={'mt-8'}
        label={'API Keys'}
        action={
          <Button
            size={'sm'}
            className={'mb-1'}
            onClick={() => setCreateKeyDialogOpen(true)}
          >
            <PlusIcon className={'h-5 w-5 mr-2'} />
            Create API Key
          </Button>
        }
      >
        {({ item }) => {
          return <APIKeyListItem apiKey={item} key={item.id} />;
        }}
      </QueriedList>

      <CreateAPIKeyDialog
        open={createKeyDialogOpen}
        onClose={() => setCreateKeyDialogOpen(false)}
      />
    </>
  );
};

export const Route = createLazyRoute('/admin/api-keys')({
  component: APIKeys,
});
