import { ReactNode } from 'react';

import { IconType } from '@/components/ui/Input.tsx';
import { cn } from '@/lib/utils.ts';

export type SurfaceLabelProps = {
  children?: ReactNode;
  icon?: IconType;
  className?: string;
  iconClassName?: string;
};

const SurfaceLabel = ({
  children,
  className,
  icon: Icon,
  iconClassName,
}: SurfaceLabelProps) => (
  <p
    className={cn(
      'text-sm font-semibold my-2 mb-2 uppercase text-gray-600 inline-flex items-center',
      {
        'sm:-ml-4': Boolean(Icon),
      },
      className,
    )}
  >
    {Icon && <Icon className={cn('h-4 w-4 mr-1', iconClassName)} />}
    {children}
  </p>
);

export default SurfaceLabel;
