import { createFileRoute } from '@tanstack/react-router'
import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { getOmnibusAccountQuery } from '@/features/administrator-panel/omnibus/api/omnibusAccountApi.ts';

export const Route = createFileRoute('/admin/omnibus/$omnibusAccountId')({
  loader: ({ params }) =>
    crownQueryClient.ensureQueryData(
      getOmnibusAccountQuery(params.omnibusAccountId),
    ),
  meta: ({ loaderData }) => [{ title: loaderData.name }],
})
