import { createFileRoute, redirect } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { requireAuthenticated } from '@/common/api/requireAuthenticated.ts';
import { queryApplicationOptions } from '@/features/application-filling/api/queryApplication.ts';
import { getIdentityVerificationQuery } from '@/features/application-filling/api/updateApplicationApi';

export const Route = createFileRoute('/application/$applicationId')({
  beforeLoad: async (ctx) => {
    const user = await requireAuthenticated(ctx);

    if (user?.showWelcomePage) {
      throw redirect({
        to: '/welcome',
        search: {
          continueSearch: ctx.search,
          continueTo: ctx.location.pathname,
        },
      });
    }
  },
  loader: async (route) => {
    return Promise.all([
      crownQueryClient.ensureQueryData(
        queryApplicationOptions(route.params.applicationId),
      ),
      crownQueryClient.ensureQueryData(
        getIdentityVerificationQuery(route.params.applicationId),
      )
    ]);
  }
});
