export const extractErrorFromResponse = async (response: Response) => {
  if (response.bodyUsed) {
    return null;
  }
  try {
    const body = await response.json();
    if ('message' in body && typeof body['message'] === 'string') {
      return body['message'];
    }
    if ('error' in body && typeof body['error'] === 'string') {
      return body['error'];
    }

    return null;
  } catch (error) {
    return null;
  }
};
