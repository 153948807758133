import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/admin/')({
  loader: () => {
    redirect({
      throw: true,
      to: '/admin/overview',
      replace: true,
    });
  },
});
