import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/portal/deposit-account/$depositAccountId/',
)({
  component: () => null,
  beforeLoad: () => {
    throw redirect({
      to: '/portal/deposit-account/$depositAccountId/transactions',
      from: '/portal/deposit-account/$depositAccountId',
      replace: true,
    });
  },
});
