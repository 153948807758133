import { LinkSimpleHorizontal } from '@phosphor-icons/react';

import { crownApi } from '@/common/api/crownQueryClient.ts';
import { QueriedListItem } from '@/components/list/QueriedListItem.tsx';
import { CopyToClipboard } from '@/components/ui/CopyToClipboard.tsx';
import { Typography } from '@/components/ui/Typography.tsx';
import { cn } from '@/lib/utils.ts';
import { Components } from '@/openapi';

type APIKeyListItemProps = {
  apiKey: Components.Schemas.APIKeyListItemDto;
};

const revealSecret = async (clientId: string) =>
  (await crownApi.APIKeysAdmin_revealKey({ keyId: clientId })).data.secret;

export const APIKeyListItem = ({ apiKey }: APIKeyListItemProps) => {
  return (
    <QueriedListItem className={'p-4 py-2 grid grid-cols-2 hover:bg-default'}>
      <div className={'flex flex-col justify-center items-start'}>
        <Typography
          variant={'h4'}
          className={
            'text-primary text-lg -mb-1 flex justify-start items-center'
          }
        >
          <LinkSimpleHorizontal className={cn('w-6 h-6 mr-2')} />
          <span>{apiKey.id}</span>
        </Typography>
        <Typography variant={'muted'}>
          Description:{' '}
          <span className={'italic'}>
            {apiKey.description || 'No description'}
          </span>
        </Typography>
      </div>
      <div className={'flex flex-col items-end justify-center'}>
        <div className={'flex justify-end items-center w-full'}>
          <span className={'text-gray-600 mr-2'}>Client ID: </span>
          <CopyToClipboard className={'max-w-xs'}>{apiKey.id}</CopyToClipboard>
        </div>
        <div className={'flex justify-end items-center w-full'}>
          <span className={'text-gray-600 mr-2'}>Client Secret: </span>
          <CopyToClipboard
            className={'max-w-xs'}
            asyncReveal={() => revealSecret(apiKey.id)}
          >
            Click to reveal secret
          </CopyToClipboard>
        </div>
      </div>
    </QueriedListItem>
  );
};
