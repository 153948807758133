import { UserGroupIcon } from '@heroicons/react/24/outline/index';
import { createLazyRoute, useSearch } from '@tanstack/react-router';
import { Link } from '@tanstack/react-router';

import DashboardHeader from '@/components/ui/layout/dashboard-layout/DashboardHeader.tsx';
import { SearchWidget } from '@/components/ui/layout/dashboard-layout/widget/SearchWidget.tsx';
import { QueriedList } from '@/components/list/QueriedList.tsx';
import { QueriedListItem } from '@/components/list/QueriedListItem.tsx';
import { listProvidersInfiniteQuery } from '@/features/administrator-panel/provider/api/providersApi.ts';
import { ProviderListItem } from '@/features/administrator-panel/provider/ui/ProviderListItem.tsx';
import {Button} from '@/components/ui/Button.tsx';

export const PROVIDERS_PER_PAGE = 20;

export const ProviderManagement = () => {
  const search = useSearch({from: '/admin/providers/'});

  return (
    <>
      <div
        className={
          'block md:flex items-center w-full max-w-dashboard-content gap-x-8'
        }
      >
        <DashboardHeader
          className={
            'text-center md:text-left md:mb-0 mb-4 flex items-center justify-center md:justify-start'
          }
        >
          <UserGroupIcon className={'w-8 h-8 text-gray-800 mr-2'} />
          Providers
        </DashboardHeader>

        <SearchWidget
          className={'flex-grow'}
          route={'/admin/providers'}
          placeholder={'Search providers...'}
        />
      </div>

      <QueriedList
        className={'mt-8'}
        headerClassName={'mb-1 items-center'}
        query={listProvidersInfiniteQuery({
          perPage: PROVIDERS_PER_PAGE,
          search: search?.search || '',
        })}
        label={'Providers'}
        action={(
          <Link
            to={`/admin/providers/add`}
          >
            <Button size={'sm'}>
              Add New Provider
            </Button>
          </Link>
        )}
      >
        {({ item }) => (
          <QueriedListItem key={item.id}>
            <ProviderListItem provider={item} />
          </QueriedListItem>
        )}
      </QueriedList>
    </>
  );
};

export const Route = createLazyRoute('/admin/providers')({
  component: ProviderManagement,
});
