import { getSessionToken, useDescope } from '@descope/react-sdk';
import toast from 'react-hot-toast';
import { create } from 'zustand';

import { crownApi, crownQueryClient } from '@/common/api/crownQueryClient';
import { getUserSession } from '@/common/user-session/userSessionApi.ts';
import { clearAppState } from '@/common/util/clearAppState';
import { prefixWith } from '@/lib/prefixWith';
import { router } from '@/routing.tsx';

type SessionDetails = { initJwt: string | null; ready: boolean };

type UserSessionState = {
  descope: ReturnType<typeof useDescope> | null;
  initDescope: (showLoader: ReturnType<typeof useDescope>) => void;

  sessionDetails: SessionDetails;
  setSessionDetails: (sessionDetails: SessionDetails) => void;
  checkSession: () => SessionDetails;

  logoutPromise: Promise<void> | null;
  logout: (params?: { redirect?: boolean }) => Promise<void>;

  magicLinkPromise: Promise<void> | null;
  magicLink: (params: { linkToken: string }) => Promise<void>;

  completeLoginPromise: Promise<void> | null;
  completeLogin: (params?: {
    redirect?: string;
    redirectSearch?: Record<string, unknown>;
  }) => Promise<void>;
};

export const useUserSessionState = create<UserSessionState>((set, get) => ({
  descope: null,
  initDescope: (descope) => {
    if (!get().descope) set({ descope });
  },

  // Sometimes Descope's useSession and useUser may have broken state despite having a valid token
  // This may especially occur after magicLink.verify as it works outside of a typical descope flow
  // Grabbing the token straight from storage and comparing with the response bypasses this issue
  // There might also be a few renders of delay between successful login and the token being mounted
  // We might also be in middle of logging out previous session - hence we must compare tokens
  sessionDetails: {
    // Once populated, this will become outdated quickly due to refreshing
    initJwt: null,
    // Therefore we also need the ready bool to lock it once confirmed
    ready: false,
  },
  checkSession: () => {
    const state = get();
    if (!state.sessionDetails.ready && state.sessionDetails.initJwt) {
      const cachedToken = getSessionToken();
      if (cachedToken === state.sessionDetails.initJwt) {
        const updated = { ...state.sessionDetails, ready: true };
        set({ sessionDetails: updated });
        return updated;
      }
    }
    return state.sessionDetails;
  },
  setSessionDetails: (sessionDetails) => {
    set({ sessionDetails });
  },

  logoutPromise: null,
  logout: async ({ redirect = true } = {}) => {
    const state = get();
    if (state.logoutPromise) return state.logoutPromise;
    const previousUser = crownQueryClient.getQueryData<
      Awaited<ReturnType<typeof getUserSession>>
    >(['session']);

    const logoutPromise = (async () => {
      await state.descope!.logout();
      set({ sessionDetails: { initJwt: null, ready: false } });
      clearAppState();
      if (redirect) {
        toast.success('Logged out successfully');
        await router.navigate({ to: '/login', replace: true });
      }

      if (previousUser) {
        localStorage.setItem(
          'dls_last_user_display_name',
          previousUser.firstName,
        );
        localStorage.setItem('dls_last_user_login_id', previousUser.email);
      }

      void crownQueryClient.invalidateQueries();
      localStorage.removeItem('admin_history');
    })();

    set({ logoutPromise });
    logoutPromise.finally(() => {
      set({ logoutPromise: null });
    });
    return logoutPromise;
  },

  magicLinkPromise: null,
  magicLink: async ({ linkToken }) => {
    const state = get();
    if (state.magicLinkPromise) return state.magicLinkPromise;

    const magicLinkPromise = (async () => {
      await state.logout({ redirect: false });
      const res = await state.descope!.magicLink.verify(linkToken);
      if (!res.ok || !res.data!.sessionJwt) throw res.error;
      set({ sessionDetails: { initJwt: res.data!.sessionJwt, ready: false } });
    })();

    set({ magicLinkPromise });
    magicLinkPromise.catch(() => {
      toast.error('The link you followed has expired');
      router.navigate({
        to: '/login',
        search: (prev) => {
          return prev
            ? {
                ...prev,
                redirect: prev.redirect as string,
                'descope-login-flow': undefined,
                redirectSearch:
                  'redirectSearch' in prev
                    ? (prev.redirectSearch as Record<string, unknown>)
                    : undefined,
                t: undefined,
              }
            : {};
        },
      });
    });
    magicLinkPromise.finally(() => {
      set({ magicLinkPromise: null });
    });
    return magicLinkPromise;
  },

  completeLoginPromise: null,
  completeLogin: async ({ redirect, redirectSearch } = {}) => {
    const state = get();
    if (state.completeLoginPromise) return state.completeLoginPromise;

    const completeLoginPromise = (async () => {
      toast.success('Logged in successfully');
      setTimeout(() => {
        router.navigate({
          to: redirect ? prefixWith('/', redirect) : '/',
          from: '/',
          search: redirectSearch,
        });
      }, 1);
      crownApi.UserSession_onLogin();
    })();

    set({ completeLoginPromise });
    completeLoginPromise.finally(() => {
      set({ completeLoginPromise: null });
    });
    return completeLoginPromise;
  },
}));
