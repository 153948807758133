import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/admin/customers')({
  meta: () => [
    {
      title: 'Customers',
    },
  ],
  component: Outlet
});
