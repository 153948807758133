import { ChevronRightIcon } from '@heroicons/react/24/solid/index';
import { Envelope, User } from '@phosphor-icons/react';
import { Link } from '@tanstack/react-router';
import { Pill } from '@/components/ui/Pill.tsx';
import { Components } from '@/openapi';

export type CustomerListItemProps = {
  customer: Components.Schemas.CustomerListItemDto;
};

export const CustomerListItem = ({ customer }: CustomerListItemProps) => {


  return (
    <div
      className={
        'p-4 grid grid-cols-4 justify-between items-center relative hover:bg-primary-foreground transition-colors'
      }
    >
      <div className={'flex flex-col items-start justify-center col-span-2'}>
        <span
          className={
            'font-semibold text-lg -mb-1 text-primary flex items-center justify-start'
          }
        >
          <User className={'w-5 h-5 mr-1 inline'}/>
          {`${customer.firstName} ${customer.lastName}`}
        </span>
        <span
          className={'text-gray-700 text-sm flex justify-start items-center'}
        >
          <Envelope className={'w-5 h-5 mr-1 text-gray-400'}/>
          {customer.email}
        </span>

        <Link
          // @ts-expect-error temp
          to={'/admin/customers/$customerId'}
          // @ts-expect-error temp
          params={{customerId: customer.id}}
        >
          <span className={'absolute inset-x-0 -top-px bottom-0'}/>
        </Link>
      </div>

      <div className={'col-span-1 hidden sm:block'}>
        {(customer.hasDepositAccounts || customer.hasVirtualAccounts) && (
          <Pill color={'primary'}>
            Account Holder
          </Pill>
        )}
      </div>

      <div className={'flex justify-end items-center col-span-2 sm:col-span-1'}>
        <ChevronRightIcon className={'h-6 w-6 text-gray-400'}/>
      </div>
    </div>
  );
};

export const CustomerListItemSkeletons = ({count}: { count: number }) => (
  <>
    {Array.from({ length: count }).map((_, i) => (
      <CustomerListItemSkeleton key={i} />
    ))}
  </>
);

export const CustomerListItemSkeleton = () => {
  return (
    <div className={'w-full p-2 py-4'}>
      <div className={'animate-pulse w-full h-10 rounded-sm bg-gray-300'} />
    </div>
  );
};
