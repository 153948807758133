import {
  DefaultError,
  infiniteQueryOptions,
  keepPreviousData,
  UseMutationOptions,
} from '@tanstack/react-query';

import { crownApi, crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { Components } from '@/openapi';

export const listTeamUserQuery = ({
  perPage,
  ...filters
}: Omit<Components.Schemas.GetTeamUsersParamsDto, 'page' | 'limit'> & {
  perPage: number;
}) =>
  infiniteQueryOptions({
    queryFn: async ({ pageParam = 1 }) => {
      return (
        await crownApi.Team_getUsers({
          page: pageParam,
          limit: perPage,
          search: filters.search,
        })
      ).data;
    },
    queryKey: ['admin', 'team', 'list', { filters, perPage }],
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    placeholderData: keepPreviousData,
  });

export const inviteUserMutation: UseMutationOptions<
  Components.Schemas.InviteUserResponseDto,
  DefaultError,
  Components.Schemas.InviteUserParamsDto
> = {
  mutationFn: async (user: Components.Schemas.InviteUserParamsDto) => {
    return (await crownApi.Team_inviteUser(null, user)).data;
  },
  onSuccess: () => {
    void crownQueryClient.invalidateQueries({
      queryKey: ['admin', 'team', 'list'],
    });
  },
};
