import BigNumber from 'bignumber.js';

export const formatCurrency = (
  amount: number | string | BigNumber,
  withSign?: boolean,
) => {
  let bigNumber: BigNumber;
  if (typeof amount === 'string' || typeof amount === 'number') {
    bigNumber = new BigNumber(amount);
  } else if (amount instanceof BigNumber) {
    bigNumber = amount;
  } else {
    return '0';
  }

  const sign = bigNumber.isNegative() ? '-' : '+';
  return `${withSign ? sign : ''}$${(withSign ? bigNumber.abs() : bigNumber).toFormat(2)}`;
};
