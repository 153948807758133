import { MutationOptions, queryOptions } from '@tanstack/react-query';

import { crownApi, crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { type Components } from '@/openapi';

export const getUserSession = async () => {
  return (await crownApi.UserSession_getSession()).data!;
};

export const getUserSessionOptions = queryOptions({
  queryFn: getUserSession,
  queryKey: ['session'],
  retry: 2,
});

export const markWelcomePageReadMutation = (
  options?: MutationOptions,
): MutationOptions => ({
  mutationFn: () => crownApi.UserSession_markWelcomePageRead(),
  ...options,
  onSuccess: async (data, variables, context) => {
    crownQueryClient.setQueryData(
      ['session'],
      (old: Components.Schemas.GetUserSessionResponse) => ({
        ...old,
        showWelcomePage: false,
      }),
    );
    await options?.onSuccess?.(data, variables, context);
  },
});
