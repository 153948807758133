import { createFileRoute } from '@tanstack/react-router'
import { ensureInfiniteQueryData } from '@/common/util/ensureInfiniteQueryData.ts';
import { listApiKeysInfiniteQuery } from '@/features/administrator-panel/api-keys/api/apiKeysApi.ts';
import { API_KEYS_PER_PAGE } from '@/features/administrator-panel/api-keys/APIKeys.tsx';

export const Route = createFileRoute('/admin/api-keys')({
  loader: () =>
    ensureInfiniteQueryData(listApiKeysInfiniteQuery(API_KEYS_PER_PAGE)),
  meta: () => [{title: 'API Keys'}]
})
