import { createFileRoute, redirect } from '@tanstack/react-router';
import { toast } from 'react-hot-toast';
import { z } from 'zod';

import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { requireAuthenticated } from '@/common/api/requireAuthenticated.ts';
import { getUserSessionOptions } from '@/common/user-session/userSessionApi.ts';
import { listUserApplicationsQuery } from '@/features/portal/start-home/api/listApplications.ts';
import { DEPOSIT_ACCOUNT_TYPES } from '@/features/portal/start-home/start-new-application-type/constants.ts';
import {
  StartNewApplicationLoader,
  StartNewApplicationSkeleton,
} from '@/features/portal/start-home/start-new-application-type/StartNewApplicationLoader.tsx';
import {
  Application,
  DepositAccountSlug,
} from '@/features/portal/start-home/start-new-application-type/types.ts';

const redirectToDefaultRoute = () => {
  throw redirect({
    to: '/',
    replace: true,
  });
};

export const Route = createFileRoute('/portal/start/new/$depositAccountType')({
  validateSearch: z
    .object({
      provider: z.string().optional(),
    })
    .optional()
    .default({}),
  beforeLoad: async (params) => {
    await requireAuthenticated(params);
    await crownQueryClient.ensureQueryData(getUserSessionOptions);

    const depositAccountType =
      DEPOSIT_ACCOUNT_TYPES[
        params.params.depositAccountType as DepositAccountSlug
      ];

    if (!depositAccountType) {
      toast.error('Invalid deposit account type', {
        id: 'incorrect-deposit-account-type',
      });
      redirectToDefaultRoute();
    }

    let applications: Application[] = [];

    try {
      const { items } = await crownQueryClient.ensureQueryData(
        listUserApplicationsQuery,
      );
      applications = items.filter(({ status }) => status === 'USER_FILLING');
    } catch (error) {
      toast.error('Something went wrong');
      redirectToDefaultRoute();
    }

    const existingDraft = applications.find(
      ({ applicationType }) => applicationType === depositAccountType,
    );

    if (existingDraft) {
      throw redirect({
        to: '/application/$applicationId',
        params: { applicationId: existingDraft.id },
        replace: true,
      });
    }
  },
  pendingComponent: StartNewApplicationSkeleton,
  component: StartNewApplicationLoader,
});
