export const TEAM_USERS_PER_PAGE = 20;

export const userRoleOptions = [
  {
    label: 'Team Member',
    value: 'PROVIDER' as const,
  },
  {
    label: 'Team Administrator',
    value: 'PROVIDER_ADMIN' as const,
  },
];
