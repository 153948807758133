import { Link } from '@tanstack/react-router';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { Envelope, Phone, Users } from '@phosphor-icons/react';

import { formatPhoneNumber } from '@/common/util/formatPhoneNumber.ts';
import { Provider } from '@/features/administrator-panel/provider/types.ts';

type ProviderListItemProps = {
  provider: Provider;
};

export const ProviderListItem = ({
  provider,
}: ProviderListItemProps) => {
  return (
    <div
      className={
        'w-full grid grid-cols-2 hover:bg-primary-foreground transition-colors relative p-4'
      }
    >
      <div className={'flex flex-col items-start justify-center'}>
        <span
          className={
            'font-semibold text-lg -mb-1 text-primary flex items-center justify-start'
          }
        >
          <Users className={'w-5 h-5 mr-1 inline'}/>
          {provider.name}
          {provider.isDefault && (' (default)')}
        </span>
        <span
          className={'text-gray-700 text-sm flex justify-start items-center'}
        >
          <Phone className={'w-5 h-5 mr-1 text-gray-400'}/>
          {formatPhoneNumber(provider.supportPhone)}
        </span>
        <span
          className={'text-gray-700 text-sm flex justify-start items-center'}
        >
          <Envelope className={'w-5 h-5 mr-1 text-gray-400'}/>
          {provider.supportEmail}
        </span>
        <Link
          to={'/admin/providers/$providerId'}
          params={{ providerId: provider.id }}
        >
          <span className={'absolute inset-x-0 -top-px bottom-0'}/>
        </Link>
      </div>

      <div className={'flex justify-end items-center'}>
        <ChevronRightIcon className={'h-6 w-6 text-gray-400'}/>
      </div>
    </div>
  );
};
