import { queryOptions } from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient.ts';
import { Components } from '@/openapi';
import {
  DepositAccountType
} from '@/features/administrator-panel/configuration/product-code-map/ProductCodeMapConfig.tsx';

export const getConfigQuery = queryOptions({
  queryFn: async () =>
    (await crownApi.AdminConfiguration_getConfiguration()).data,
  queryKey: ['admin', 'configuration'],
});

export const uploadFileMutationFn = async (file: File) => {
  const fileExtensionReg = /.*\.(.*)/.exec(file.name);
  const { url, publicUrl } = (
    await crownApi.AdminConfiguration_generatePublicFileUploadURL(null, {
      fileExtension: fileExtensionReg ? fileExtensionReg[1] : undefined,
    })
  ).data;

  await crownApi.put(url, file);

  return publicUrl;
};

export const getDepositAccountCodeMapConfigQuery = queryOptions({
  queryFn: async () =>
    (await crownApi.AdminConfiguration_getDepositAccountCodeConfig()).data,
  queryKey: ['admin', 'deposit-account-code-map-config'],
});

export const getAvailableDepositAccountCodesQuery = (
  accountType: DepositAccountType,
) => ({
  ...getDepositAccountCodeMapConfigQuery,
  select: (data: Components.Schemas.GetDepositAccountCodeConfigResponseDto) =>
    data.availableCodes.filter((code) =>
      data.config.some((c) => c.code === code.code && c.accountType === accountType),
    ),
});
