import { cva, type VariantProps } from 'class-variance-authority';
import { forwardRef, HTMLAttributes } from 'react';

import { cn } from '@/lib/utils.ts';

export type PillProps = VariantProps<typeof pillVariants> &
  HTMLAttributes<HTMLDivElement>;

const pillVariants = cva('rounded-2xl inline-block', {
  variants: {
    color: {
      primary: 'bg-primary-foreground border border-primary text-primary',
      tag: 'bg-gray-100 border border-gray-400 text-gray-600',
      success: 'bg-success-foreground border border-success text-success',
      error: 'bg-error-foreground border border-error text-error',
      warning: 'bg-warning-foreground border border-warning text-warning',
      disabled: 'bg-gray-50 border border-gray-200 text-gray-400',
    },
    size: {
      xs: 'text-xs py-0 px-1',
      sm: 'text-sm py-0 px-2',
      md: 'text-sm py-1 px-2',
      lg: 'text-base py-2 px-3',
    },
  },
  defaultVariants: {
    color: 'primary',
    size: 'md',
  },
});

export type PillColor = PillProps['color'];

export const Pill = forwardRef<HTMLSpanElement, PillProps>(
  ({ color, size, ...props }, ref) => {
    return (
      <span
        {...props}
        ref={ref}
        className={cn(pillVariants({ color, size }), props.className)}
      />
    );
  },
);
