import { createFileRoute, redirect } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { requireAuthenticated } from '@/common/api/requireAuthenticated.ts';
import { getUserSessionOptions } from '@/common/user-session/userSessionApi.ts';

export const Route = createFileRoute('/')({
  beforeLoad: async (params) => {
    await requireAuthenticated(params);
    const session = await crownQueryClient.fetchQuery(
      getUserSessionOptions,
    );

    if (session.role === 'PROCESSOR' || session.role === 'ADMIN') {
      throw redirect({
        to: '/admin',
        replace: true,
      });
    }

    if (session.role === 'PROVIDER' || session.role === 'PROVIDER_ADMIN') {
      throw redirect({
        to: '/provider/$providerId',
        params: { providerId: session.providerId! },
        replace: true,
      });
    }

    throw redirect({
      to: '/portal',
      replace: true,
    });
  },
  component: () => null,
});
