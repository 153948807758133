import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { ensureInfiniteQueryData } from '@/common/util/ensureInfiniteQueryData.ts';
import { APPLICATIONS_PER_PAGE } from '@/components/ui/applications/constants.ts';
import { listApplicationsInfiniteQuery } from '@/features/administrator-panel/applications/api/applicationsListApi.ts';
import { objectKeys } from '@/lib/objectKeys.ts';
import { type Components } from '@/openapi';
import { applicationStatusFilters, applicationTypeFilters } from '@/common/const/applicationFilterOptions.ts';

export const Route = createFileRoute('/admin/applications/')({
  validateSearch: (search) =>
    z
      .object({
        search: z.string().optional(),
        statusFilter: z
          .array(
            z.enum<
              Components.Schemas.ApplicationStatus,
              [Components.Schemas.ApplicationStatus, ...Components.Schemas.ApplicationStatus[]]
            >(objectKeys(applicationStatusFilters)),
          )
          .optional()
          .catch(() => []),
        type: z
          .array(
            z.enum<
              Components.Schemas.ApplicationType,
              [Components.Schemas.ApplicationType, ...Components.Schemas.ApplicationType[]]
            >(objectKeys(applicationTypeFilters)),
          )
          .optional()
          .catch(() => []),
      })
      .default({})
      .parse(search),
  loader: () =>
    ensureInfiniteQueryData(
      listApplicationsInfiniteQuery({
        limit: APPLICATIONS_PER_PAGE,
        filters: { search: '', status: ['PENDING_APPROVAL'] },
      }),
    ),
});
