import { createFileRoute } from '@tanstack/react-router';



import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { getCustomerQuery } from '@/features/administrator-panel/customer-view/api/customerViewApi.ts';
import { fullName } from '@/lib/fullName.ts';

export const Route = createFileRoute('/admin/customers/$userId')({
  loader: ({ params }) =>
    crownQueryClient.ensureQueryData(getCustomerQuery(params.userId)),
  meta: ({ loaderData }) => {
    return [
      {
        title: fullName(loaderData.individual),
      },
    ];
  },
});
