import { infiniteQueryOptions, keepPreviousData, queryOptions } from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient.ts';
import { Components } from '@/openapi';

export const listApplicationsInfiniteQuery = ({
  limit = 10,
  filters,
}: Omit<Components.Schemas.ListApplicationsParamsDto, 'page'> = {}) =>
  infiniteQueryOptions({
    queryFn: async ({ pageParam = 1, signal }) =>
      (
        await crownApi.ApplicationsIndex_listApplications(
          {
            page: pageParam,
            limit,
            filters,
          },
          null,
          {
            signal,
          },
        )
      ).data,
    queryKey: ['applications', { limit, filters, infinite: true }],
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    getPreviousPageParam: (firstPage) => firstPage.prevCursor,
    initialPageParam: 1,
    placeholderData: keepPreviousData
  });

export const listApplicationsQuery = ({
  limit = 10,
  page,
  filters,
}: Components.Schemas.ListApplicationsParamsDto)  => queryOptions({
  queryFn: async () =>
    (
      await crownApi.ApplicationsIndex_listApplications(
        {
          page: page || 1,
          limit,
          filters,
        }
      )
    ).data,
  queryKey: ['applications', { limit, page, filters, infinite: false }],
})
