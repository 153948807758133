import { createFileRoute } from '@tanstack/react-router'
import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { getApplicationQuery } from '@/features/administrator-panel/review-application/api/reviewApi.ts';
import { APPLICATION_TYPE_LABELS } from '@/common/i18n/applicationTypeLabels.ts';

export const Route = createFileRoute('/admin/applications/$applicationId')({
  loader: ({ params }) => crownQueryClient.ensureQueryData(getApplicationQuery(params.applicationId)),
  meta: ({ loaderData }) => [{
    title: `${loaderData.lastName} ${APPLICATION_TYPE_LABELS[loaderData.applicationType]}`,
  }],
})
