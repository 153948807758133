import { crownApi } from '@/common/api/crownQueryClient.ts';
import { Components } from '@/openapi';
import { queryOptions } from '@tanstack/react-query';

export const confirmPersonalDetails = async ({
  applicationId,
  ...params
}: Components.Schemas.ConfirmPersonalInfoParamsDto & {
  applicationId: string;
}) => {
  return (
    await crownApi.ApplicationFilling_completePersonalInfo(
      { applicationId },
      params,
    )
  ).data!;
};

export const getIdentityVerificationQuery = (applicationId: string) =>
  queryOptions({
    queryFn: async () =>
      (
        await crownApi.ApplicationFilling_getIdVerification({
          applicationId,
        })
      ).data!,
    queryKey: ['application', applicationId, 'idVerification'] as const,
  });

export const confirmIdentityVerificationCompleted = async ({
  applicationId
}: { applicationId: string; }) => {
  return (
    await crownApi.ApplicationFilling_confirmIDVerification(
      { applicationId }
    )
  ).data!;
};

export const confirmContactDetails = async ({
  applicationId,
  ...params
}: Components.Schemas.ConfirmContactDetailsParamsDto & {
  applicationId: string;
}) => {
  return (
    await crownApi.ApplicationFilling_completeContactDetails(
      { applicationId },
      params,
    )
  ).data!;
};

export const confirmComplianceInfo = async ({
  applicationId,
  ...params
}: Components.Schemas.ConfirmComplianceInfoParamsDto & {
  applicationId: string;
}) => {
  return (
    await crownApi.ApplicationFilling_confirmComplianceInfo(
      { applicationId },
      params,
    )
  ).data!;
};

export const confirmRetirementAccount = async ({
  applicationId,
  ...params
}: Components.Schemas.ConfirmRetirementAccountDetailsParamsDto & {
  applicationId: string;
}) => {
  return (
    await crownApi.ApplicationFilling_completeRetirementAccount(
      { applicationId },
      params,
    )
  ).data!;
};

export const agreeLegalDisclosures = async ({
  applicationId,
  ...params
}: Components.Schemas.AgreeLegalDisclosuresParamsDto & {
  applicationId: string;
}) => {
  return (
    await crownApi.ApplicationFilling_agreeWithLegalDisclosures(
      { applicationId },
      params,
    )
  ).data!;
};
