import { useQuery } from '@tanstack/react-query';

import { getThemingQuery } from '@/api/configApi.ts';

export const useTheming = () => {
  const { data: theming } = useQuery(getThemingQuery);

  if (!theming) {
    throw new Error(
      'Unable to use the useTheming, not in an application route context.',
    );
  }

  return theming;
};
