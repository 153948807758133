import { createFileRoute } from '@tanstack/react-router'
import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import {
  getProviderUserQuery
} from '@/features/administrator-panel/provider-view/provider-user-view/api/providerUserViewApi.ts';
import { fullName } from '@/lib/fullName.ts';

export const Route = createFileRoute('/admin/providers/$providerId/users/$userId')({
  loader: ({ params }) => crownQueryClient.ensureQueryData(
    getProviderUserQuery(params.providerId, params.userId),
  ),
  meta: ({ loaderData }) => [{
    title: fullName(loaderData.individual),
  }],
})
