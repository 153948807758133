import { queryOptions } from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient.ts';

export const getProviderInfoQueryInfo = (providerId: string) => queryOptions({
  queryFn: async () =>
    (await crownApi.ProviderPortal_getInfo({
      providerId,
    })).data,
  queryKey: ['provider', providerId],
});
