import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import { FC, Fragment, ReactNode, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { EmptyListState } from '@/components/list/EmptyListState.tsx';
import { QueriedListItemSkeletons } from '@/components/list/QueriedListItemSkeleton.tsx';
import { DashboardContentSurface } from '@/components/ui/layout/dashboard-layout/DashboardContentSurface.tsx';
import SurfaceLabel from '@/components/ui/layout/dashboard-layout/SurfaceLabel.tsx';
import { ErrorState } from '@/components/ui/states/ErrorState.tsx';
import { cn } from '@/lib/utils.ts';

export type QueryDataWithItems<TItem extends QueryDataItem> = {
  items: TItem[];
  currentCursor: number;
  total: number;
};
export type QueryDataItem = { id: string };

export type QueriedListProps<TData extends QueryDataWithItems<QueryDataItem>> =
  {
    query: UseInfiniteQueryOptions<
      TData,
      any,
      InfiniteData<TData>,
      any,
      any,
      number
    >;
    children: ({ item }: { item: TData['items'][number] }) => ReactNode;
    itemsPerPage?: number;
    errorComponent?: FC<any>;
    label?: string;
    className?: string;
    headerClassName?: string;
    empty?: {
      title: string;
      message: string;
    };
    action?: ReactNode;
    onLoadingChange?: (state: boolean) => void;
  };

export const QueriedList = <TData extends QueryDataWithItems<QueryDataItem>>({
  query,
  children,
  itemsPerPage = 10,
  errorComponent: ErrorComponent = ErrorState,
  label,
  empty = {
    title: 'No items found',
    message: 'No items could be found',
  },
  className,
  headerClassName,
  action,
  onLoadingChange,
}: QueriedListProps<TData>) => {
  const {
    data,
    isLoading,
    isFetching,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(query);
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && !isFetchingNextPage && hasNextPage) void fetchNextPage();
  }, [fetchNextPage, isFetchingNextPage, hasNextPage, inView]);

  useEffect(() => {
    onLoadingChange && onLoadingChange(isFetching);
  }, [isFetching]);

  const totalItems = data?.pages[0]?.total || 0;

  const remainingItemCount =
    totalItems - (data?.pages?.flatMap((page) => page.items)?.length || 0);

  return (
    <DashboardContentSurface
      header={
        label && (
          <div className={cn('flex w-full justify-between', headerClassName)}>
            <SurfaceLabel>{`${label}${totalItems > 0 ? ` (${totalItems})` : ''}`}</SurfaceLabel>
            {action}
          </div>
        )
      }
      containerClassName={className}
      className={'p-0'}
    >
      <div className={'divide-y divide-gray-200'}>
        {data && totalItems === 0 && <EmptyListState {...empty} />}
        {isError && <ErrorComponent />}
        {data &&
          data.pages.map((page) => (
            <Fragment key={page.currentCursor}>
              {page.items.map((item) => children({ item }))}
            </Fragment>
          ))}
        {isLoading && <QueriedListItemSkeletons count={itemsPerPage} />}
        {isFetchingNextPage && (
          <QueriedListItemSkeletons
            count={Math.min(remainingItemCount, itemsPerPage)}
          />
        )}
        <span ref={ref} className={'invisible'} />
      </div>
    </DashboardContentSurface>
  );
};
