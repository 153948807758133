import { infiniteQueryOptions, keepPreviousData } from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient.ts';
import { Components } from '@/openapi';

export const listDepositAccountsQuery = ({
  limit,
  search,
}: Omit<Components.Schemas.ListDepositAccountsParamsDto, 'page'>) =>
  infiniteQueryOptions({
    queryFn: async ({ pageParam = 1 }) => {
      return (
        await crownApi.AdminDepositAccount_listDepositAccounts({
          page: pageParam,
          limit,
          search,
        })
      ).data;
    },
    queryKey: ['admin', 'deposit-accounts', 'list', { search, limit }],
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    placeholderData: keepPreviousData,
  });
