import { createFileRoute } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { listOmnibusAccountsQuery } from '@/features/administrator-panel/omnibus/api/omnibusAccountApi.ts';
import { OMNIBUS_ACCOUNTS_PER_PAGE } from '@/features/administrator-panel/omnibus/OmnibusAccountsGrid.tsx';

export const Route = createFileRoute('/admin/omnibus/')({
  loader: () =>
    crownQueryClient.fetchInfiniteQuery(
      listOmnibusAccountsQuery({ perPage: OMNIBUS_ACCOUNTS_PER_PAGE }),
    ),
});
