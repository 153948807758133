import { createFileRoute } from '@tanstack/react-router'
import { requireAuthenticated } from '@/common/api/requireAuthenticated.ts';
import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { getApplicationStatusOptions } from '@/features/application-status/api/applicationStatusApi.ts';

export const Route = createFileRoute('/application-status/$applicationId')({
  beforeLoad: requireAuthenticated,
  loader: ({ params }) =>
    crownQueryClient.ensureQueryData(
      getApplicationStatusOptions(params.applicationId),
    ),
})
