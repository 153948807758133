import { redirect } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient';
import { useUserSessionState } from '@/common/user-session/state';
import { getUserSessionOptions } from '@/common/user-session/userSessionApi';

export const filterSearchRedirect = (search: Record<string, unknown>) => {
  return Object.fromEntries(
    Object.entries(search).filter(
      ([key]) =>
        !['redirect', 'redirectSearch', 'descope-login-flow', 't'].some(
          (blacklistItem) => blacklistItem === key,
        ),
    ),
  );
};

export const requireAuthenticated = async ({
  location,
  search,
}: {
  location: {
    pathname: string;
  };
  search: Record<string, unknown>;
}) => {
  const { checkSession } = useUserSessionState.getState();
  const sessionDetails = checkSession();

  if (search.t || !sessionDetails.ready) {
    throw redirect({
      to: '/login',
      search: {
        redirect: location.pathname,
        'descope-login-flow': search['descope-login-flow'] as string,
        redirectSearch: filterSearchRedirect(search),
        t: search.t as string,
      },
    });
  }

  // Preload user data when logged in
  if (sessionDetails.ready) {
    try {
      return await crownQueryClient.ensureQueryData(getUserSessionOptions);
    } catch (error) {
      if (location.pathname !== '/login') {
        throw redirect({
          to: '/login',
          search: {
            ...search,
            redirect: (search.redirect as string) ?? location.pathname,
            redirectSearch: filterSearchRedirect(search),
            'descope-login-flow': search['descope-login-flow'] as string,
            t: search.t as string,
          },
          replace: true,
        });
      }
    }
  }
};
