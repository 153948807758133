import { createFileRoute, Outlet } from '@tanstack/react-router';

import Spinner from '@/components/ui/Spinner.tsx';
import { ErrorState } from '@/components/ui/states/ErrorState.tsx';

export const Route = createFileRoute(
  '/portal/deposit-account/$depositAccountId/transfers',
)({
  component: Outlet,
  errorComponent: ErrorState,
  pendingComponent: () => (
    <div className={'flex items-center justify-center py-80'}>
      <Spinner className={'w-14 h-14'} />
    </div>
  ),
  meta: () => [{ title: 'Transfers' }],
});
