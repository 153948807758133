import { HTMLAttributes, ReactNode } from 'react';

import { cn } from '@/lib/utils.ts';

type InputLabelProps = {
  className?: string;
  htmlFor?: string;
  children: ReactNode;
  fontSize?: string;
} & HTMLAttributes<HTMLLabelElement>;

const InputLabel = ({ className, children, fontSize = 'text-sm', ...props }: InputLabelProps) => (
  <label className={cn('relative block font-medium text-gray-900', className, fontSize)} {...props}>
    {children}
  </label>
);

InputLabel.defaultProps = {
  className: '',
  htmlFor: '',
  fontSize: 'text-sm',
};

export default InputLabel;
