import { queryOptions } from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient.ts';

export const getProviderUserQuery = (providerId: string, userId: string) =>
  queryOptions({
    queryFn: async () =>
      (await crownApi.ProviderManagement_getUser({ providerId, userId })).data,
    queryKey: ['providers', 'provider', { providerId }, 'users', { userId }],
  });
