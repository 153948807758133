import { createFileRoute } from '@tanstack/react-router';

import { ensureInfiniteQueryData } from '@/common/util/ensureInfiniteQueryData.ts';
import { listDepositAccountsQuery } from '@/features/administrator-panel/deposit-accounts/api/depositAccountsApi.ts';
import { DEPOSIT_ACCOUNTS_PER_PAGE } from '@/features/administrator-panel/deposit-accounts/DepositAccounts.tsx';

export const Route = createFileRoute('/admin/deposit-accounts/')({
  validateSearch: (search: Record<string, unknown>) => {
    return {
      search: (search.search as string) || undefined,
    };
  },
  loader: () =>
    ensureInfiniteQueryData(
      listDepositAccountsQuery({ limit: DEPOSIT_ACCOUNTS_PER_PAGE }),
    ),
});
