import {infiniteQueryOptions, keepPreviousData, queryOptions} from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient.ts';
import {Components} from '@/openapi';

export const getProviderQuery = (providerId: string) =>
  queryOptions({
    queryFn: async () =>
      (await crownApi.ProviderManagement_getProvider({ providerId })).data,
    queryKey: ['providers', 'provider', { providerId }],
  });

export const listProviderApplicationsInfiniteQuery = ({
  perPage,
  providerId,
  ...filters
}: Components.Schemas.ListProviderApplicationsParamsDto & {
  providerId: string;
  perPage: number;
}) =>
  infiniteQueryOptions({
    queryFn: async ({ pageParam = 1 }) => {
      return (
        await crownApi.ProviderManagement_listProviderApplications({
          providerId,
          page: pageParam,
          limit: perPage,
        })
      ).data;
    },
    queryKey: ['admin', 'providers', 'provider', { providerId }, 'applications', { filters, perPage }],
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    placeholderData: keepPreviousData,
  });
