import {
  infiniteQueryOptions,
  keepPreviousData,
} from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient.ts';
import { Components } from '@/openapi';

export const listProvidersInfiniteQuery = ({
  perPage,
  ...filters
}: Components.Schemas.ProvidersListFiltersDto & {
  perPage: number;
}) =>
  infiniteQueryOptions({
    queryFn: async ({ pageParam = 1 }) => {
      return (
        await crownApi.ProviderManagement_listProviders({
          page: pageParam,
          limit: perPage,
          filters,
        })
      ).data;
    },
    queryKey: ['admin', 'providers', 'list', { filters, perPage }],
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    placeholderData: keepPreviousData,
  });
