import { WrenchScrewdriverIcon } from '@heroicons/react/24/outline';
import { createFileRoute } from '@tanstack/react-router'

import { ErrorState } from '@/components/ui/states/ErrorState.tsx';
import Spinner from '@/components/ui/Spinner.tsx';
import DashboardHeader from '@/components/ui/layout/dashboard-layout/DashboardHeader.tsx';
import { Typography } from '@/components/ui/Typography.tsx';
import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { getProviderInfoQueryInfo } from '@/features/provider-panel/tools/api/providerInfoApi.ts';

export const Route = createFileRoute('/provider/$providerId/tools/')({
  errorComponent: ErrorState,
  pendingComponent: () => (
    <div>
      <div
        className={
          'block md:flex items-center w-full max-w-dashboard-content gap-x-8'
        }
      >
        <DashboardHeader>
          <WrenchScrewdriverIcon
            className={'flex-none w-6 h-6 md:w-8 md:h-8 text-gray-800 mr-2'}
          />
          Tools
        </DashboardHeader>
      </div>
      <div
        className={
          'max-w-dashboard-content w-full flex justify-center items-center h-96'
        }
      >
        <Spinner className={'w-16 h-16'}/>
      </div>
      <Typography className={'invisible p-4 border'}>
        You can generate and share the link with the customer to start the application for opening a deposit account.
      </Typography>
    </div>
  ),
  loader: async ({params}) =>
    crownQueryClient.ensureQueryData(getProviderInfoQueryInfo(params.providerId)),
});
