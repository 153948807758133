export const TEAM_USERS_PER_PAGE = 20;

export const userRoleOptions = [
  {
    label: 'Processor',
    value: 'PROCESSOR' as const,
  },
  {
    label: 'Admin',
    value: 'ADMIN' as const,
  },
];
