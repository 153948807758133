import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/provider/$providerId/')({
  beforeLoad: () => {
    throw redirect({
      to: '/provider/$providerId/applications',
      replace: true,
      from: '/provider/$providerId',
    });
  },
});
