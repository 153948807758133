import InputMask from '@mona-health/react-input-mask';
import React, {
  ComponentProps,
  ComponentPropsWithoutRef,
  ElementRef,
  ElementType,
  FC,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  ReactNode,
  Ref,
  useId,
} from 'react';
import { useFormContext } from 'react-hook-form';
import type JSX from 'react/jsx-runtime';

import { dotNotationToValue } from '@/common/util/dotNotationToValue.ts';
import HelperText from '@/components/ui/HelperText';
import InputErrorMessage from '@/components/ui/InputErrorMessage';
import InputLabel from '@/components/ui/InputLabel';
import { Typography } from '@/components/ui/Typography.tsx';
import { cn } from '@/lib/utils.ts';

export type IconType = FC<{
  className?: string;
}>;

// Define a type for the component prop that includes HTML elements and any custom components
type ComponentType = ElementType;

// Conditional type for the ref based on the component type
type RefType<T extends ComponentType> =
  T extends keyof JSX.JSX.IntrinsicElements
    ? ElementRef<T>
    : T extends React.ComponentType<infer P>
      ? P extends { ref?: infer R }
        ? R
        : never
      : never;

export type InputSize = 'md' | 'lg';

type ExtendedInputProps<T extends ElementType> = T extends 'input'
  ? JSX.JSX.IntrinsicElements[T] & { type?: string } // Add specific input attributes here
  : ComponentPropsWithoutRef<T>;

export type ErrorType = string | boolean | undefined | null;

export type InputProps<T extends ElementType> = Omit<
  ExtendedInputProps<T>,
  'size'
> & {
  className?: string;
  containerProps?: HTMLAttributes<HTMLDivElement>;
  icon?: IconType;
  iconProps?: ComponentPropsWithoutRef<IconType>;
  labelProps?: Omit<ComponentPropsWithoutRef<'label'>, 'children'>; // Assuming 'InputLabel' is a 'label'
  helperText?: ReactNode;
  error?: ErrorType | ErrorType[];
  label?: string;
  inputClassName?: string;
  size?: InputSize;
  mask?: string;
  disabled?: boolean;
  ref?: Ref<RefType<T>>;
  InputComponent?: T;
  name?: string;
  optional?: boolean;
};
const InputComponent = <T extends ComponentType = 'input'>(
  {
    icon: Icon,
    iconProps,
    containerProps,
    labelProps,
    helperText,
    error,
    label,
    inputClassName,
    size = 'md',
    mask,
    optional = false,
    InputComponent: DefaultInputComponent = 'input' as T,
    ...props
  }: InputProps<T>,
  ref: ForwardedRef<unknown>,
) => {
  const inputId = useId();
  const InputComponent = mask ? InputMask : DefaultInputComponent;
  const formState = useFormContext();

  let formError: ErrorType[] | ErrorType | undefined = undefined;

  if (
    props.name &&
    dotNotationToValue(props.name, formState?.formState?.errors)
  ) {
    formError = dotNotationToValue<{ message: ErrorType }>(
      props.name,
      formState?.formState?.errors,
    )!.message as ErrorType;
  }

  error = error || formError;

  return (
    <div {...containerProps} className={props.className}>
      <InputLabel
        htmlFor={inputId}
        {...labelProps}
        fontSize={cn({
          'text-sm': size === 'md',
          'text-base': size === 'lg',
        })}
      >
        {label}
        {optional && <Typography variant={'muted'} className={'text-sm'}>{` (optional)`}</Typography>}
      </InputLabel>
      <div className="relative rounded-md shadow-sm">
        {Icon && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <Icon
              className={cn(
                'text-gray-500',
                {
                  'h-5 w-5': size === 'md',
                  'h-6 w-6': size === 'lg',
                },
                iconProps,
              )}
            />
          </div>
        )}
        <InputComponent
          ref={ref}
          mask={mask!}
          id={inputId}
          {...{ ...props, className: undefined }}
          {...((InputComponent === 'input' || InputComponent === InputMask) &&
          !(props as ComponentProps<'input'>).type
            ? { type: 'text' }
            : {})}
          className={cn(
            'p-3 w-full block mt-1 rounded-md border-input text-gray-900 shadow-sm focus:border-app-theme-500 focus:ring-app-theme-500 inputNumberNoArrow transition-colors',
            { 'pl-10': Icon && size === 'md' },
            { 'pl-12': Icon && size === 'lg' },
            {
              'border-red-500':
                (Array.isArray(error) && error.length > 0) ||
                (!Array.isArray(error) && error),
            },
            { 'text-sm': size === 'md' },
            { 'text-lg': size === 'lg' },
            {
              'bg-gray-50 text-gray-500 cursor-not-allowed select-none':
                props.disabled,
            },
            inputClassName,
          )}
        />
      </div>
      <InputErrorMessage message={typeof error === 'boolean' ? '' : error} />
      {helperText && <HelperText className="mt-1">{helperText}</HelperText>}
    </div>
  );
};

export const Input = forwardRef(InputComponent);
