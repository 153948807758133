import { getSessionToken } from '@descope/react-sdk';
import { createRootRoute, redirect } from '@tanstack/react-router';

import { getThemingQuery } from '@/api/configApi.ts';
import App from '@/App.tsx';
import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { filterSearchRedirect } from '@/common/api/requireAuthenticated.ts';
import { useUserSessionState } from '@/common/user-session/state.ts';

export const Route = createRootRoute({
  component: App,
  pendingMs: 500,
  validateSearch: (
    search: Record<string, unknown>,
  ):
    | {
        redirect: string | undefined;
        redirectSearch: Record<string, unknown> | undefined;
        'descope-login-flow': string | undefined;
        t: string | undefined;
      }
    | undefined => ({
    redirect: (search.redirect as string) || undefined,
    redirectSearch:
      typeof search.redirectSearch === 'object'
        ? (search.redirectSearch as Record<string, unknown>)
        : undefined,
    'descope-login-flow': (search['descope-login-flow'] as string) || undefined,
    t: (search.t as string) || undefined,
  }),
  loader: () => crownQueryClient.ensureQueryData(getThemingQuery),
  beforeLoad: async ({ search, location }) => {

    // Landing with a login token should take the user to the
    // login page regardless if they land on public or auth url
    if (search?.t) {
      // Note: This is a parent route and so we need to check the pathname
      //       or we will end up with an infinite loop
      if (location.pathname !== '/login') {
        throw redirect({
          to: '/login',
          search: {
            ...search,
            redirect: search.redirect || location.pathname,
            redirectSearch:
              search.redirectSearch || filterSearchRedirect(location.search),
          },
          replace: true,
        });
      }

      return;
    }

    // Mount already logged in user if available
    const sessionToken = getSessionToken();
    const userSessionState = useUserSessionState.getState();
    if (sessionToken && !userSessionState.sessionDetails.ready) {
      userSessionState.setSessionDetails({
        initJwt: sessionToken,
        ready: false,
      });
    }

    // Fallback: do nothing and let requireAuthenticated() kick in as needed
  },
});
