import {
  DefaultError,
  FetchInfiniteQueryOptions,
  QueryKey,
} from '@tanstack/react-query';

import { crownQueryClient } from '@/common/api/crownQueryClient.ts';

export const ensureInfiniteQueryData = <
  TQueryFnData,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: FetchInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryKey, any>,
): Promise<void> => {
  const cachedData = crownQueryClient.getQueryData<TData[]>(options.queryKey);

  if (cachedData === undefined)
    return crownQueryClient.fetchInfiniteQuery(options).then(() => undefined);
  else {
    return Promise.resolve(undefined);
  }
};
