import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import { useEffect } from 'react';

import { Button } from '@/components/ui/Button.tsx';
import HelperText from '@/components/ui/HelperText.tsx';
import { Typography } from '@/components/ui/Typography.tsx';
import { listUserApplicationsQuery } from '@/features/portal/start-home/api/listApplications.ts';
import { DEPOSIT_ACCOUNT_TYPES } from '@/features/portal/start-home/start-new-application-type/constants.ts';
import {
  ApplicationType,
  DepositAccountSlug,
} from '@/features/portal/start-home/start-new-application-type/types.ts';

import { startApplicationMutation } from '@/features/portal/start-home/api/startApi.ts';

const InputSkeleton = ({ label }: { label: string }) => (
  <>
    <div className={'w-full text-sm font-medium'}>{label}</div>
    <div
      className={
        'w-full p-3 mt-1 rounded-md border-[1px] border-input shadow-sm animate-pulse text-sm text-gray-300 bg-gray-300'
      }
    >
      .
    </div>
  </>
);

export const StartNewApplicationSkeleton = () => {
  return (
    <>
      <Typography variant={'h3'} className={'text-center mb-8'}>
        Personal Details
      </Typography>

      <div className={'grid grid-cols-3 gap-4'}>
        {['First Name', 'Middle Name', 'Last Name'].map((label) => (
          <div key={label} className={'col-span-3 md:col-span-1'}>
            <InputSkeleton label={label} />
          </div>
        ))}

        <div className={'col-span-3 md:col-span-1'}>
          <InputSkeleton label={'Gender'} />
        </div>
      </div>

      <HelperText>
        Enter your full legal name as it appears on your government-issued ID.
      </HelperText>

      <Typography variant={'h4'} className={'mt-8'}>
        Home Address
      </Typography>

      <div className={'grid grid-cols-3 gap-4 mt-4'}>
        <div className={'col-span-3 md:col-span-2'}>
          <InputSkeleton label={'Street Address'} />
        </div>

        <div className={'col-span-3 md:col-span-1'}>
          <InputSkeleton label={'Street Address'} />
        </div>

        {['City', 'State', 'ZIP Code'].map((label) => (
          <div key={label} className={'col-span-3 md:col-span-1'}>
            <InputSkeleton label={label} />
          </div>
        ))}
      </div>

      <HelperText>
        {`Enter your current legal residence address. We'll ask you for a mailing
      address later.`}
      </HelperText>

      <div className={'flex justify-end mt-8'}>
        <Button className={'animate-pulse'} disabled={true}>
          Continue
        </Button>
      </div>
    </>
  );
};

export const StartNewApplicationLoader = () => {
  const { depositAccountType } = useParams({
    from: '/portal/start/new/$depositAccountType',
  });
  const { provider: providerIdSlug } = useSearch({
    from: '/portal/start/new/$depositAccountType',
  });

  const queryClient = useQueryClient();
  const navigate = useNavigate({
    from: `/portal/start/new/$depositAccountType`,
  });

  const { mutate } = useMutation({
    ...startApplicationMutation,
    onSuccess: (data) => {
      void queryClient.fetchQuery(listUserApplicationsQuery);
      void navigate({
        to: '/application/$applicationId',
        replace: true,
        params: {
          applicationId: data.id,
        },
      });
    },
  });

  useEffect(() => {
    const isMutating = queryClient.isMutating();
    if (isMutating) return;
    mutate({
      type: DEPOSIT_ACCOUNT_TYPES[
        depositAccountType as DepositAccountSlug
      ] as ApplicationType,
      providerIdSlug: providerIdSlug as string | undefined
    });
  }, []);

  return <StartNewApplicationSkeleton />;
};
