import { Link } from '@tanstack/react-router';
import { ComponentProps, HTMLAttributes } from 'react';

import { cn } from '@/lib/utils.ts';

export type QueriedListItemProps = {
  link?: Omit<ComponentProps<typeof Link>, 'children' | 'className'>;
} & HTMLAttributes<HTMLDivElement>;

export const QueriedListItem = ({
  link,
  children,
  ...props
}: QueriedListItemProps) => {
  return (
    <div className={cn('relative hover:bg-primary-foreground transition-colors', props.className)}>
      {children}
      {link && (
        <Link {...link} className={'absolute inset-x-0 -top-px bottom-0'} />
      )}
    </div>
  );
};
