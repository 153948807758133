import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/Tooltip.tsx';
import { cn } from '@/lib/utils.ts';

export type CopyToClipboardProps = {
  children: string;
  asyncReveal?: () => Promise<string>;
  className?: string;
};

export const CopyToClipboard = ({
  children,
  asyncReveal,
  className,
}: CopyToClipboardProps) => {
  const [asyncValue, setAsyncValue] = useState<null | string>(null);

  const asyncMode = asyncReveal !== undefined;

  const copyValue = async () => {
    try {
      if (!asyncMode) {
        void navigator.clipboard.writeText(children);
        toast.success('Copied to clipboard');
        return;
      }

      if (asyncValue) {
        void navigator.clipboard.writeText(asyncValue);
        toast.success('Copied to clipboard');
        return;
      }

      await asyncReveal!().then((value) => {
        setAsyncValue(value);
        void navigator.clipboard.writeText(value);
        toast.success('Copied to clipboard');
      });
    } catch (error) {
      console.error(error);
      toast.error('Failed to copy to clipboard');
      Sentry.captureException(error);
    }
  };

  return (
    <TooltipProvider delayDuration={100}>
      <Tooltip>
        <TooltipTrigger asChild>
          <span
            onClick={() => copyValue()}
            className={cn(
              'relative bg-gray-100 border-gray-200 border p-1 py-0.5 rounded cursor-pointer select-none mb-1 w-full overflow-hidden truncate hover:bg-gray-200 transition-colors',
              {
                italic: asyncMode && !asyncValue,
              },
              className,
            )}
          >
            <span>{asyncMode ? asyncValue || children : children}</span>
          </span>
        </TooltipTrigger>
        <TooltipContent className={'dark'}>
          <span>Click to copy</span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
