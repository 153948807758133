import { Components } from '@/openapi';

export const applicationStatusFilters: {
  [key in Components.Schemas.ApplicationStatus]: string;
} = {
  CANCELLED: 'Cancelled',
  PENDING_APPROVAL: 'Open Applications',
  REJECTED: 'Rejected Applications',
  APPROVED: 'Approved Applications',
  USER_FILLING: 'Draft/In Progress Applications',
  PROVIDER_FILLING: 'Draft/In Progress by Provider',
  STEP_UP_REQUIRED: 'Need Additional User Information',
};

export const applicationTypeFilters: {
  [key in Components.Schemas.ApplicationType]: string;
} = {
  IRA: 'IRA',
  IRA_LLC_DEPOSIT: 'IRA LLC Deposit',
  TRUST_DEPOSIT: 'Trust Deposit',
  IRA_DEPOSIT: 'IRA (Deposit)',
  SOLO_401K: 'Solo 401(k) Deposit',
  LLC: 'LLC',
  BUSINESS: 'Business',
};
