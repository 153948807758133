import { queryOptions } from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient.ts';

export const checkAuthQueryOptions = (providerId: string) => queryOptions({
  queryFn: async () =>
    (await crownApi.ProviderPortal_checkAuth({
      providerId,
    })).data,
  queryKey: ['provider', providerId, 'isProvider'],
  retry: 3,
  staleTime: 1000 * 60 * 60,
  refetchOnMount: true
});

export const getDraftApplicationsCountQueryOptions = (providerId: string) => queryOptions({
  queryFn: async () =>
    (await crownApi.ProviderApplications_getDraftApplicationsCount({
      providerId,
    })).data,
  queryKey: ['provider', providerId, 'draftApplicationsCount'],
});
