import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

export const Route = createFileRoute('/welcome')({
  meta: () => [{ title: 'Welcome' }],
  validateSearch: z
    .object({
      continueTo: z.string().optional(),
      continueSearch: z.any().optional(),
    })
    .optional()
    .default({}),
});
