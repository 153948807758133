import { ErrorBoundary } from '@sentry/react';
import { HTMLAttributes, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import SurfaceLabel from '@/components/ui/layout/dashboard-layout/SurfaceLabel.tsx';
import { Typography } from '@/components/ui/Typography.tsx';
import { cn } from '@/lib/utils.ts';
import { CloudX } from '@phosphor-icons/react';

export type DashboardContentSurfaceProps = {
  children: ReactNode;
  header?: ReactNode;
  containerClassName?: string;
} & HTMLAttributes<HTMLDivElement>;

export const DashboardContentSurface = ({
  children,
  header,
  containerClassName,
  ...props
}: DashboardContentSurfaceProps) => (
  <div className={cn('max-w-dashboard-content w-full', containerClassName)}>
    {typeof header === 'string' ? (
      <SurfaceLabel>{header}</SurfaceLabel>
    ) : (
      header
    )}
    <div
      {...props}
      className={twMerge(
        'w-full rounded-xl shadow-md border bg-white p-4 overflow-hidden',
        props.className,
      )}
    >
      <ErrorBoundary
        fallback={
          <div className={'p-4 py-12 flex items-center justify-center flex-col gap-y-2'}>
            <CloudX className={'w-10 h-10 text-error/80'} />

            <Typography variant={'h4'} className={'text-error text-center'}>
              An internal server error occurred loading this widget
            </Typography>
          </div>
        }
      >
        {children}
      </ErrorBoundary>
    </div>
  </div>
);
