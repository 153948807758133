import { useAutoAnimate } from '@formkit/auto-animate/react';
import { HTMLAttributes } from 'react';

import { ErrorType } from '@/components/ui/Input.tsx';

type InputErrorMessageProps = {
  message?: ErrorType | ErrorType[];
  children?: never;
} & Omit<HTMLAttributes<HTMLDivElement>, 'children'>;

const InputErrorMessage = ({ message, ...props }: InputErrorMessageProps) => {
  const [errorRef] = useAutoAnimate();
  const formattedMessage = Array.isArray(message) ? message.filter((msg) => !!msg).join(', ') : message;
  return (
    <div ref={errorRef} {...props}>
      {message && (
        <span
          className="text-sm text-red-500"
          key={Array.isArray(message) ? (message[0] as string) : (message as string)}
        >
          {formattedMessage}
        </span>
      )}
    </div>
  );
};

export default InputErrorMessage;
