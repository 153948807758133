import { crownApi } from '@/common/api/crownQueryClient.ts';
import { Components } from '@/openapi';

export const startApplication = async (
  params: Components.Schemas.StartApplicationParamsDto,
) => {
  return (await crownApi.Applications_startApplication(null, params)).data!;
};
export const startApplicationMutation = {
  mutationFn: startApplication,
};
