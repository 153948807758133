export const dotNotationToValue = <TResponse>(
  dotNotation: string,
  object: object,
) => {
  return dotNotation
    .split('.')
    .reduce((o, i) => o ? (o as { [key: string]: any })[i] : undefined, object) as
    | TResponse
    | undefined;
};
